import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Alert, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip} from "@mui/material";
import {AddOutlined, RemoveOutlined, WarningOutlined} from "@mui/icons-material";
import {UserGroup} from "pages/UserGroupsPage";
import {DataGridItemActions} from "components/DataGrid";
import Button from "@mui/material/Button";
import {
    type ContentLearningEventDayIndexResponse,
    ContentLearningEventDayIndexResponseParticipantsInner,
    ContentManagerUserGroupDashboardContentResponseLearningEvent,
    DayCreate,
    DayUpdate
} from "generated-api";
import {UserGroupLearningEventDayDialog} from "components/userGroup/UserGroupLearningEventDayDialog";
import {deleteLearningEventDay, fetchLearningEvent, fetchLearningEventDays, updateLearningEventParticipant, updateLearningEventParticipantDay} from "store/userGroup";
import {isApiResultError} from "../../helpers/api";
import {addMessage} from "store/localApp";
import {useModal} from "services/modal";
import {datetimeToGui, dateToGui, timeToGui} from "../../helpers/date";
import {CheckboxPlain} from "components/form/CheckboxFormField";
import {SxProps} from "@mui/system";
import {NoteCell} from "components/userGroup/NoteCell";
import {EditParticipantNoteDialog} from "components/userGroup/EditParticipantNoteDialog";

interface LearningEvent extends ContentManagerUserGroupDashboardContentResponseLearningEvent {
    // with days
}

interface LearningEventDay extends ContentLearningEventDayIndexResponse {

}

interface Participant extends ContentLearningEventDayIndexResponseParticipantsInner {

}

const tableStyle: SxProps = {
    '& > .MuiTableRow-root .MuiTableCell-root': {
        color: 'var(--color-black-text)',
        '&:first-of-type': {
            width: '45px',
            maxWidth: '45px',
            'button': {
                width: 'auto',
                minWidth: 'unset'
            }
        }
    },
    '& > .MuiTableRow-root:first-of-type .MuiTableCell-root': {
        background: 'var(--color-black80)',
        color: 'var(--color-white)',
        'svg': {
            color: 'inherit'
        }
    },
    '& > .MuiTableRow-root:nth-of-type(2) .MuiTableCell-root': {
        borderBottom: '1px solid #8D8D8D',
    },
    '& + .MuiTableBody-root': {
        borderTop: '12px solid white'
    }
};

const UserGroupLearningEventDayParticipant = (
    {
        learningEventId,
        dayIndex,
        participant,
        isFinalDay,
        hasMassActions,
        onEditParticipantNote
    }: {
        learningEventId: number,
        dayIndex: number,
        participant: Participant,
        isFinalDay?: boolean,
        hasMassActions: boolean,
        onEditParticipantNote: (participant: Participant) => void,
    }) => {
    // const t = useAppTranslation();
    const pd = participant.participant_days?.[dayIndex];

    const dispatch = useAppDispatch();

    const [isParticipation, setIsParticipation] = useState<boolean | undefined>(pd?.participation);
    const [isCertificateApproved, setIsCertificateApproved] = useState<boolean | undefined>(participant.certificate_approved);


    const onToggleParticipation = useCallback(async () => {
        if (!pd?.id) {
            return;
        }
        const prevValue = isParticipation;
        setIsParticipation(undefined); // instant feedback
        const res = await dispatch(updateLearningEventParticipantDay({
            id: String(pd?.id),
            learningEventId,
            body: {
                participation: !prevValue
            }
        }));
        if (isApiResultError(res)) {
            setIsParticipation(prevValue);
        } else {
            setIsParticipation(!isParticipation);
        }

    }, [isParticipation, pd, learningEventId, dispatch]);

    const onToggleCertificate = useCallback(async () => {
        const prevValue = isCertificateApproved;
        setIsCertificateApproved(undefined); // instant feedback
        const res = await dispatch(updateLearningEventParticipant({
            id: String(participant.id),
            learningEventId,
            body: {
                certificate_approved: !prevValue
            }
        }));
        if (isApiResultError(res)) {
            setIsCertificateApproved(prevValue);
        } else {
            setIsCertificateApproved(!isCertificateApproved);
        }

    }, [isCertificateApproved, participant.id, learningEventId, dispatch]);

    return <TableRow sx={{'&:nth-of-type(odd)': {background: '#efefef'}}}>
        <TableCell>
            {hasMassActions && <CheckboxPlain name={'toggleRow' + participant.id}/>}
        </TableCell>
        <TableCell><Tooltip title={participant.email} enterDelay={500}><strong>{participant.full_name}</strong></Tooltip></TableCell>
        <TableCell>{!!pd?.content_progress?.assigned_contents
            && <span>{pd?.content_progress?.assigned_contents.completed} / {pd?.content_progress?.assigned_contents.total}</span>}</TableCell>
        <TableCell>{!!pd?.content_progress?.participations_progress
            && <span>{pd?.content_progress?.participations_progress.completed} / {pd?.content_progress?.participations_progress.total}</span>}</TableCell>
        <TableCell>{!!pd?.content_progress?.tests_progress
            && <span>{pd?.content_progress?.tests_progress.completed} / {pd?.content_progress?.tests_progress.total}</span>}</TableCell>
        <TableCell>
            <CheckboxPlain name={'toggleParticipation' + participant.id}
                currentValue={isParticipation}
                readonly={isParticipation === undefined}
                onChange={onToggleParticipation}/>
        </TableCell>
        {isFinalDay ? <TableCell>
            <CheckboxPlain name={'toggleCertificate' + participant.id} currentValue={isCertificateApproved}
                readonly={isCertificateApproved === undefined}
                onChange={onToggleCertificate}/>
        </TableCell> : <TableCell></TableCell>}
        <NoteCell note={participant.note} handleEdit={() => onEditParticipantNote(participant)}/>
        <TableCell></TableCell>
    </TableRow>;
}

const UserGroupLearningEventDay = (
    {
        learningEventId, day, dayIndex, isFinalDay,
        onRemoveDay, onEditDay,
        onEditParticipantNote,
        hasMassActions
    }: {
        learningEventId: number,
        day: LearningEventDay,
        dayIndex: number,
        isFinalDay?: boolean,
        onRemoveDay: (day: LearningEventDay) => void,
        onEditDay: (day: LearningEventDay) => void,
        onEditParticipantNote: (participant: Participant) => void,
        hasMassActions: boolean,
    }) => {
    const t = useAppTranslation();
    // const dispatch = useAppDispatch();

    const [isCollapsed, setIsCollapsed] = useState(true);

    return <TableBody sx={tableStyle}>
        <TableRow>
            <TableCell>
                <Button color={'inherit' as any} variant={'text'}
                    onClick={() => setIsCollapsed(!isCollapsed)}>
                    {isCollapsed ? <AddOutlined/> : <RemoveOutlined/>}
                </Button>
            </TableCell>
            <TableCell sx={{width: '12%'}}>
                <strong>{t('userGroup.days.day')}</strong>
                <div>{t('userGroup.days.day')} {dayIndex + 1}</div>
            </TableCell>
            <TableCell sx={{width: '12%'}}>
                <strong>{t('userGroup.days.date')}</strong>
                <div>{dateToGui(day.start_date)}</div>
            </TableCell>
            <TableCell sx={{width: '12%'}}>
                <strong>{t('userGroup.days.time')}</strong>
                <div>{timeToGui(day.start_date, 'HH:mm')} - {timeToGui(day.end_date, 'HH:mm')}</div>
            </TableCell>
            <TableCell sx={{width: '12%'}}>
                <strong>{t('userGroup.days.form')}</strong>
                <div>{t('userGroup.form.' + day.form)}</div>
            </TableCell>
            <TableCell sx={{width: '12%'}}>
                <strong>{t('userGroup.days.location')}</strong>
                {!!day.physical_location && <div>{day.physical_location}</div>}
                {!!day.online_location && <div>{day.online_location}</div>}
            </TableCell>
            <TableCell sx={{width: '30%'}} colSpan={2}>
                <strong>{t('userGroup.days.lectors')}</strong>
                <div>{day.lecturers?.map(u => u.full_name).join(", ")}</div>
            </TableCell>
            <DataGridItemActions item={day} isActionMenu actions={[
                {title: 'userGroup.days.editDay', callback: onEditDay},
                {title: 'userGroup.days.removeDay.action', callback: onRemoveDay, color: 'error'},
            ]}/>
        </TableRow>
        {!isCollapsed && <TableRow>
            <TableCell>
                {hasMassActions && <CheckboxPlain name={'toggleAll'}/>}
            </TableCell>
            <TableCell>{t('userGroup.days.name')}</TableCell>
            <TableCell>{t('userGroup.days.contentFulfilled')}</TableCell>
            <TableCell>{t('userGroup.days.courseProgress')}</TableCell>
            <TableCell>{t('userGroup.days.testProgress')}</TableCell>
            <TableCell>{t('userGroup.days.attendance')}</TableCell>
            {isFinalDay
                ? <TableCell sx={{width: '10%'}}>{t('userGroup.days.certificate')}</TableCell>
                : <TableCell sx={{width: '10%'}}></TableCell>}
            <TableCell>{t('userGroup.days.note')}</TableCell>
            <TableCell></TableCell>
        </TableRow>}

        {!isCollapsed && !day.participants?.length && <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={7}>
                <div>{t('common.emptyList')}</div>
            </TableCell>
        </TableRow>}
        {!isCollapsed && day.participants?.length && day.participants
            .sort((a, b) => a.full_name?.localeCompare(b.full_name))
            .map((participant) =>
                <UserGroupLearningEventDayParticipant key={participant.id}
                    learningEventId={learningEventId}
                    dayIndex={dayIndex}
                    isFinalDay={isFinalDay}
                    participant={participant}
                    hasMassActions={hasMassActions}
                    onEditParticipantNote={onEditParticipantNote}
                />)}
    </TableBody>
}

export const UserGroupDaysTab = ({userGroup}: { userGroup: UserGroup }) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const [learningEvent, setLearningEvent] = useState<LearningEvent>();
    const [learningEventDays, setLearningEventDays] = useState<LearningEventDay[]>();
    const [editDay, setEditDay] = useState<DayCreate | DayUpdate>();
    const [participant, setParticipant] = useState<Participant>();
    const [isLoading, setIsLoading] = useState(false);

    const fetchEvent = useCallback(async () => {
        if (!userGroup.learning_event?.id) {
            return;
        }
        setIsLoading(true);
        try {
            const res = await dispatch(fetchLearningEvent({id: String(userGroup.learning_event.id)}));
            if (!isApiResultError(res)) {
                const learningEvent = res.payload as LearningEvent;

                const days = (await dispatch(fetchLearningEventDays({learningEventId: userGroup.learning_event.id})))?.payload as LearningEventDay[];

                setLearningEvent(learningEvent);
                setLearningEventDays(days || learningEvent.days);
            }
        } finally {
            setIsLoading(false);
        }
    }, [userGroup, dispatch]);

    const handleAddDay = useCallback(async () => {
        if (!learningEvent?.id) {
            return;
        }
        setEditDay({});
    }, [learningEvent?.id]);

    const handleEditDay = useCallback(async (day: LearningEventDay) => {
        if (!learningEvent?.id) {
            return;
        }
        setEditDay(day);
    }, [learningEvent?.id]);

    const handleRemoveDay = useCallback(async (day: LearningEventDay) => {
        if (!learningEvent?.id) {
            return;
        }
        const result = await modal.confirm({
            title: t('userGroup.days.removeDay.title'),
            message: t('userGroup.days.removeDay.body', {start_date: datetimeToGui(day.start_date), end_date: timeToGui(day.end_date)}),
            confirmText: t('userGroup.days.removeDay.button')
        });
        if (result !== 'CONFIRM') {
            return;
        }
        await dispatch(deleteLearningEventDay({learningEventId: learningEvent.id, id: '' + day.id}));
        dispatch(addMessage({
            severity: 'success',
            title: t('userGroup.days.removeDay.success')
        }));
        await fetchEvent();
    }, [learningEvent?.id, fetchEvent, modal, dispatch, t]);

    const handleEditParticipantNote = useCallback((participant: Participant) => {
        setParticipant(participant);
    }, []);

    useEffect(() => {
        fetchEvent().then();
    }, [fetchEvent]);

    if (learningEvent === undefined) {
        return <LinearProgress/>;
    }

    const hasMassActions = false;

    return <Grid container sx={{background: '#fff'}}>
        <Grid item xs={12} className={'tw-relative'}>
            {isLoading && <LinearProgress className={'tw-absolute tw-left-0 tw-right-0'}/>}
            {!learningEvent?.days?.length && <Alert severity={'warning'}
                icon={<WarningOutlined/>}>
                {t('userGroup.days.empty')}
            </Alert>}
            <TableContainer sx={{padding: '16px 24px', '.MuiCheckbox-root:not(.Mui-checked)': {'.MuiSvgIcon-root': {color: '#B8B8B8'}}}}>
                <Table sx={{'th.MuiTableCell-head, th, td': {padding: '4px 4px', border: 'none', '.MuiFormControlLabel-root': {margin: 0}}}}>
                    {learningEventDays?.map((day, i) => <UserGroupLearningEventDay key={i}
                        learningEventId={learningEvent.id}
                        dayIndex={i}
                        day={day}
                        isFinalDay={i === learningEventDays.length - 1}
                        onRemoveDay={handleRemoveDay}
                        onEditDay={handleEditDay}
                        onEditParticipantNote={handleEditParticipantNote}
                        hasMassActions={hasMassActions}
                    />)}
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={8}>
                                <Button color={'dark' as any} variant={'text'}
                                    onClick={handleAddDay}
                                    className={'tw-w-auto tw-normal-case'}>
                                    <AddOutlined/>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{t('userGroup.days.addDay')}
                                </Button>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        {!!editDay && learningEvent?.id &&
            <UserGroupLearningEventDayDialog
                learningEventId={learningEvent.id}
                dayId={(editDay as LearningEventDay).id}
                day={editDay}
                onSuccess={async () => {
                    await fetchEvent()
                }}
                onClose={() => {
                    setEditDay(undefined);
                }}/>}
        {participant && learningEvent?.id && <EditParticipantNoteDialog participant={participant}
            learningEventId={learningEvent.id}
            onSuccess={fetchEvent}
            onClose={() => setParticipant(undefined)}/>}
    </Grid>;
}
