import {ContentLearningEventShowResponseOrdersInner, ContentLearningEventUserSearchInOrderCompanySpaceUserSearchResponse} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {isApiResultError} from "../../helpers/api";
import {Formik, FormikErrors, FormikProps} from "formik";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import 'react-quill/dist/quill.snow.css';
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {createLearningEventParticipant, searchUserForLearningEvent} from "store/userGroup";
import {defaultDialogStyle} from "utils/dialog";
import {TextFormField} from "components/form/TextFormField";
import {addMessage} from "store/localApp";
import {CheckboxFormField} from "components/form/CheckboxFormField";
import {SelectFormField} from "components/form/SelectFormField";
import {OptionValue} from "model/form";

interface ValuesType {
    search?: string,
    email?: string,
    user_id?: number,
    name?: string,
    invitationLink: string
    sendInvite?: boolean,
}

const dialogStyle = defaultDialogStyle();

export const OrderParticipantDialog = ({order, learningEventId, onSuccess, onClose}: {
    order: ContentLearningEventShowResponseOrdersInner,
    learningEventId: number,
    onSuccess: (result: any) => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);

    // user autocomplete
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [userSearch, setUserSearch] = useState('');
    const [userOptions, setUserOptions] = useState<OptionValue[]>([]);

    const initialValues: ValuesType = useMemo(() => {
        return {
            invitationLink: order.invitation_link
        }
    }, [order.invitation_link]);

    const handleCopyLink = useCallback(async () => {
        await navigator.clipboard.writeText(order.invitation_link);
        dispatch(addMessage({
            severity: 'success',
            title: t('userGroup.registration.link.copied')
        }));
    }, [order.invitation_link, dispatch, t]);

    const handleEmailChange = useCallback((setFieldValue: FormikProps<ValuesType>['setFieldValue']) => async (value: any) => {
        const o = userOptions?.find(o => o.value === value);
        setFieldValue('user_id', o?.value, true);

        const user = o?.params?.user as ContentLearningEventUserSearchInOrderCompanySpaceUserSearchResponse;
        setFieldValue('email', user?.email);
        setFieldValue('name', user?.full_name);
    }, [userOptions]);

    const handleSave = useCallback(async (values: ValuesType) => {

        if (!values.user_id) {
            return;
        }

        setIsSaving(true);

        const res = await dispatch(createLearningEventParticipant({
            learningEventId,
            body: {
                // name: values.name,
                // email: values.email,
                // send_invitation: values.invitationLink,
                user_in_company_space_id: values.user_id
            }
        }));
        if (!isApiResultError(res)) {
            onSuccess(res.payload);
            onClose();
        }
        setIsSaving(false);
    }, [learningEventId, dispatch, onSuccess, onClose]);

    const handleValidate = useCallback((values: ValuesType) => {
        let errors = {} as FormikErrors<ValuesType>;
        if (!values.user_id) {
            errors.search = t('userGroup.registration.addParticipant.email.messages.required')
        }
        return errors;
    }, [t]);

    useEffect(() => {
        if (!userSearch) {
            setUserOptions([]);
            return;
        }

        setIsUserLoading(true);
        dispatch(searchUserForLearningEvent({
            email: userSearch,
            orderId: order.id!,
            learningEventId
        })).then((res) => {
            if (!isApiResultError(res) && res.payload) {
                const items = res.payload as ContentLearningEventUserSearchInOrderCompanySpaceUserSearchResponse[];
                const options: OptionValue[] = items
                    .filter(u => !!u.id && !u.existing_participant)
                    .map(u => ({value: u.id, label: u.full_name + ' (' + u.email + ')', params: {user: u}}));
                setUserOptions(current => {
                    const currentlySelected = current.find(o => o.label === userSearch);
                    if (currentlySelected && !options.find(o => o.label === userSearch)) {
                        options.unshift(currentlySelected);
                    }
                    return options;
                });
            } else {
                setUserOptions([]);
            }
        }).finally(() => {
            setIsUserLoading(false);
        })
    }, [learningEventId, order.id, userSearch, dispatch]);

    return (
        <Dialog open={true} onClose={onClose} PaperComponent={Paper} sx={dialogStyle} maxWidth={'md'}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSave}
                validate={handleValidate}
            >
                {({handleSubmit, setFieldValue}) => <form onSubmit={handleSubmit}>
                    <DialogTitle className={'tw-flex tw-w-full tw-justify-between tw-items-center'}>
                        <span>{t('userGroup.registration.addParticipant.label')} - {order.company_space?.name}</span>
                        <IconButton onClick={onClose} sx={{color: 'inherit'}}><Close/></IconButton>
                    </DialogTitle>
                    <LinearProgress hidden={!isSaving}/>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SelectFormField
                                    name={'search'}
                                    options={userOptions}
                                    // renderOption={renderUserOption}
                                    onSearch={setUserSearch}
                                    isLoading={isUserLoading}
                                    onChange={handleEmailChange(setFieldValue)}
                                    label={t('userGroup.registration.addParticipant.email.label')}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <TextFormField name={'name'} label={t('userGroup.registration.addParticipant.name')} disabled/>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item className={'tw-flex-grow'}>
                                        <TextFormField name={'invitationLink'} label={t('userGroup.registration.addParticipant.link')} disabled/>
                                    </Grid>
                                    <Grid item className={'tw-flex tw-flex-col tw-justify-center tw-gap-8'}>
                                        <Button color={'dark' as any} variant={'contained'} onClick={handleCopyLink}>
                                            {t('userGroup.registration.addParticipant.copyLink')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxFormField
                                    name={'sendInvite'}
                                    label={t('userGroup.registration.addParticipant.sendInvite')}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{padding: '16px'}}>
                        <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                            {t('form.cancel')}
                        </Button>
                        <Button color={'primary'} variant={'contained'} type={'submit'} disabled={isSaving}>
                            {t('userGroup.registration.addParticipant.button')}
                        </Button>
                    </DialogActions>
                </form>}
            </Formik>
        </Dialog>
    );
};
