import {useAppTranslation} from "services/i18n";
import {TableCell, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import {EditOutlined} from "@mui/icons-material";
import * as React from "react";
import {SxProps} from "@mui/system";

const noteSx: SxProps = {
    justifyContent: 'left',
    '& > div': {
        maxWidth: '200px',
        textAlign: 'left',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // fontSize: '80%',
        // lineHeight: 1,
        textTransform: 'none !important',
        fontWeight: 'normal',
        letterSpacing: 'normal',
        '& *': {
            margin: '0',
            display: 'inline',
            '& + *': {
                marginLeft: '5px'
            }
        }
    }
}

export const NoteCell = ({note, handleEdit, isCreateDisabled}: { note?: string, handleEdit: () => void, isCreateDisabled?: boolean }) => {
    const t = useAppTranslation();

    return <TableCell>{note ? <Tooltip enterDelay={500} title={<div>
            <strong>{t('userGroup.registration.note')}</strong>
            <div dangerouslySetInnerHTML={{__html: note}}></div>
        </div>}><Button color={'inherit'} variant={'text'} onClick={handleEdit} sx={noteSx}>
            <div dangerouslySetInnerHTML={{__html: note}}/>
        </Button></Tooltip>
        : (isCreateDisabled ? null : <Button color={'inherit'} variant={'text'} onClick={handleEdit}><EditOutlined/></Button>)
    }</TableCell>
}
