import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Chip,
    createFilterOptions,
    CreateFilterOptionsConfig,
    FilterOptionsState,
    Grid,
    TextField
} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {FormFieldProps, OptionValue} from '../../model/form';
import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {AutocompleteRenderGetTagProps} from "@mui/material/Autocomplete/Autocomplete";
import {AddRounded, EditRounded} from "@mui/icons-material";
import {useAppTranslation} from "services/i18n";
import {LoadingSpinner} from "components/loading/LoadingSpinner";
import CircularProgress from "@mui/material/CircularProgress";

interface Props extends FormFieldProps {
    options?: OptionValue[],
    placeholder?: string;
    isMulti?: boolean;
    clearable?: boolean;
    showTooltip?: boolean;
    matchFromStart?: boolean;
    freeSolo?: boolean;
    getTagColor?: (option: OptionValue) => string;
    editProps?: {
        modal: (
            value: any,
            onCancel: () => void,
            onSave: (res: any) => void
        ) => JSX.Element
    },
    onSearch?: (search: string) => void,
    currentOptions?: OptionValue[],
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: OptionValue) => JSX.Element,
    isLoading?: boolean,
    isInitializing?: boolean
}

const isOptionEqualToValue = (option: OptionValue, value: any) => {
    return option.value + '' === (value.value || value) + '';
}

const getOption = (value: any, options: OptionValue[]): OptionValue | undefined => {
    if (value?.label) {
        // multi
        return value as OptionValue;
    }
    const o = options
        .find((c) => {
            const v = '' + c.value;
            return v === value + '';
        });
    return o || undefined; /* invalid */
}

export const SelectFormField = (props: Props) => {

    const t = useAppTranslation();

    const {
        name, label, options, onChange, isMulti, clearable, placeholder, disabled,
        showTooltip, matchFromStart, freeSolo, getTagColor, editProps, renderOption,
        onSearch, isLoading, isInitializing
    } = props;

    const [field, meta, helpers] = useField(name);
    const {submitCount} = useFormikContext();

    const [searchStatus, setSearchStatus] = useState<'pending' | 'found' | 'empty'>();

    const [editItem, setEditItem] = useState<any | undefined>(undefined);
    const searchTimeout = useRef<any>();

    const showError = !!meta.error && (meta.touched || submitCount > 0);
    const metaError = meta.error;
    const formSetValue = helpers.setValue;

    const renderOptionDefault = useCallback((props: React.HTMLAttributes<HTMLLIElement>, option: OptionValue) => (
        <li {...props} key={'item-' + option.value} title={option.tooltip}>
            {option.icon ? <small>{option.icon} </small> : null}{option.label}
            {showTooltip && option.tooltip ? <>&nbsp;<small>({option.tooltip})</small></> : null}
        </li>
    ), [showTooltip]);

    const getOptionLabel = useCallback((value: any) => {
        return getOption(value, options || [])?.label || (isMulti ? value : '' /* invalid */);

    }, [options, isMulti]);

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return <TextField
            {...params}
            margin={'dense'}
            fullWidth
            id={name}
            name={name}
            label={label}
            hiddenLabel={!label}
            title={typeof label == 'string' ? label : placeholder}
            error={showError}
            helperText={showError && metaError}
            variant={'standard'}
            size={'small'}
            inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            placeholder={placeholder}
            disabled={disabled}
            InputProps={{
                ...params.InputProps,
                endAdornment: isInitializing ? <CircularProgress color="inherit" size={14} /> : params.InputProps.endAdornment
            }}
        />
    }, [showError, metaError, isInitializing, name, label, placeholder, disabled]);

    const renderTags = useCallback((value: readonly string[], getTagProps: AutocompleteRenderGetTagProps) => {
        return value.map((option: string, index: number) => {
            const o = options?.find((o) => isOptionEqualToValue(o, option));
            const title = (o?.label || option) as string;
            return <Chip variant="filled" sx={{
                '&.MuiChip-root.Mui-disabled': {
                    opacity: 1,
                    '.MuiChip-deleteIcon': {
                        display: 'none'
                    }
                },
                '&.MuiChip-root > .MuiChip-label': {
                    display: 'flex',
                    alignItems: 'center'
                },
                '&.MuiChip-root > .MuiChip-label > img ': {
                    marginLeft: '-8px'
                }
            }}
                title={o?.tooltip || title}
                style={o && getTagColor ? {backgroundColor: getTagColor(o)} : undefined}
                label={o?.icon ? <>{o?.icon} {title}</> : title}{...getTagProps({index})} />
        })
    }, [options, getTagColor]);

    const handleOnChange = useCallback((_: any, newValue: OptionValue | OptionValue[] | null) => {
        let v: (OptionValue | string | number | boolean)[] | string | undefined;
        if (isMulti) {
            if (newValue instanceof Array) {
                v = newValue.map(o => o.value === undefined ? o : o.value);
                v = v.filter((item, i) => (v as any[]).indexOf(item) === i); // remove duplicates
            } else {
                v = [];
            }
        } else {
            const vv = freeSolo
                ? (newValue?.hasOwnProperty('value') ? (newValue as any).value : newValue)
                : (newValue as OptionValue)?.value;
            if (vv || vv === 0 || vv === false) {
                v = vv;
            } else {
                v = undefined;
            }
        }

        formSetValue(v, true);
        if (onChange) {
            onChange(v);
        }
    }, [isMulti, freeSolo, onChange, formSetValue]);

    const filterOptions: ((options: OptionValue[], state: FilterOptionsState<OptionValue>) => OptionValue[]) | undefined = useMemo(() => {
        let c: CreateFilterOptionsConfig<OptionValue> = {};
        if (matchFromStart) {
            c['matchFrom'] = "start";
        }
        if (showTooltip) {
            c['stringify'] = (o: OptionValue) => {
                return (o.label + ' ' + o.tooltip).toLowerCase();
            }
        }
        return Object.keys(c).length > 0 ? createFilterOptions(c) : undefined;
    }, [matchFromStart, showTooltip]);

    const onInputChange = useCallback((_: any, value: string) => {
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
            searchTimeout.current = undefined;
        }
        if (value === '' && !onSearch) { // just selected & returning back, keep current
            setSearchStatus(undefined);
            return;
        }
        setSearchStatus('pending');
        searchTimeout.current = setTimeout(() => {
            if (onSearch) {
                onSearch(value);
            }
        }, 250);
    }, [onSearch, isMulti]);

    useEffect(() => {
        setSearchStatus(current => {
            if (isLoading) {
                return 'pending';
            }
            if (!!options?.length) {
                return 'found';
            }
            return current ? 'empty' : current;
        })

    }, [options, isLoading]);

    const handleOnBlur = useCallback(() => {
        if (isMulti || !field.value || freeSolo) {
            return;
        }
        if (!options?.find(o => o.value === field.value)) {
            formSetValue(undefined, true);
            if (onChange) {
                onChange(undefined);
            }
        }

    }, [options, field.value, isMulti, freeSolo, formSetValue, onChange]);

    useEffect(() => {
        return () => {
            if (searchTimeout.current) {
                clearTimeout(searchTimeout.current);
            }
        }
    }, []);

    const currentValue = (field.value || field.value === 0 || field.value === false) ? field.value : (isMulti ? [] : null);
    const el = <Autocomplete
        fullWidth
        disableClearable={!clearable}
        id={name}
        value={currentValue}
        filterOptions={filterOptions}
        // inputValue={inputValue}
        freeSolo={freeSolo || searchStatus === undefined}
        clearOnBlur={freeSolo}
        autoSelect={freeSolo}
        options={options || []}
        multiple={isMulti}
        autoHighlight={!freeSolo}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption || renderOptionDefault}
        isOptionEqualToValue={(o, v) => isOptionEqualToValue(o, v)}
        renderInput={renderInput}
        renderTags={renderTags}
        onChange={handleOnChange}
        onBlur={freeSolo ? undefined : handleOnBlur}
        onInputChange={onSearch ? onInputChange : undefined}
        componentsProps={{
            paper: {
                sx: {
                    minWidth: 'clamp(200px, 100%, 100%)',
                    width: 'fit-content'
                }
            }
        }}
        ChipProps={{
            size: 'small'
        }}
        // clearText={t('Vymazat')}
        // closeText={t('Zavřít')}
        noOptionsText={isLoading || searchStatus === 'pending' ? <LoadingSpinner size={30}/> : t('common.emptyList')}
        // openText={t('Otevřít')}
        sx={{
            '& .MuiAutocomplete-tag': {
                margin: '2px 8px 6px 0'
            },
            // '& .MuiInput-root .MuiAutocomplete-tag + .MuiInput-input': {
            //     marginLeft: '8px'
            // }
        }}
        disabled={disabled}
    />;

    if (editProps?.modal) {
        return <><Grid container>
            <Grid item sx={{flexGrow: 1}}>
                {el}
            </Grid>
            <Grid item><Button variant={'contained'} color={'inherit'} className={'quick-add-button'} onClick={() => {
                setEditItem(currentValue);
            }}> {currentValue ? <EditRounded/> : <AddRounded/>}</Button>
            </Grid>
        </Grid>
            {editItem !== undefined && editProps.modal(editItem,
                () => {
                    setEditItem(undefined);
                },
                (newValue) => {
                    formSetValue(newValue, true);
                    setEditItem(undefined);
                    if (onChange) {
                        onChange(newValue);
                    }
                })}
        </>;
    }
    return el;
}
