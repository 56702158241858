import {Button, DialogActions, DialogContent, DialogTitle, LinearProgress, Paper} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {Subject} from 'rxjs';
import {useAppTranslation} from "./i18n";
import {Color} from "../model/form";
import Dialog from "@mui/material/Dialog";

interface Props {
}

export type ModalButtons = 'CONFIRM' | 'CANCEL';
export type ModalMode = 'INFO' | 'CONFIRM';

export type ModalProps = {
    title: string,
    message: string | JSX.Element,
    mode: ModalMode;
    confirmColor?: Color,
    confirmText?: string,
    confirmIcon?: JSX.Element,
    cancelText?: string,
    cancelIcon?: JSX.Element,
    syncAction?: () => any
}

export interface ModalState extends ModalProps {
    isOpen: boolean;
}

const {createContext, useContext} = React;

export const ModalContext = createContext<any>(null);

const getDefaultState = (t: any): ModalState => ({
    isOpen: false,
    mode: 'INFO',
    title: t('modal.title'),
    message: t('modal.message'),
    confirmColor: "primary"
});

export const ModalProvider: React.FC<Props> = (props: any) => {

    const t = useAppTranslation();

    const [modal, setModal] = useState(getDefaultState(t));
    const [button, setButton] = useState(new Subject<ModalButtons>());
    const [isCallingAction, setIsCallingAction] = useState(false);

    const open = useCallback((props: ModalProps) => {
        setModal((modal) => ({...getDefaultState(t), ...props, isOpen: true}));
    }, [t]);

    const close = useCallback(() => {
        setModal((modal) => ({...modal, isOpen: false}));
    }, []);

    const value = {
        info: (props: ModalProps) => {
            open({...props, mode: 'INFO'});
            const myButton = new Subject<ModalButtons>();
            setButton(myButton);
            return new Promise((resolve, reject) => {
                const subscription = myButton && myButton.subscribe((value: ModalButtons) => {
                    close();
                    subscription.unsubscribe();
                    resolve(value);
                });
            });
        },
        confirm: (props: ModalProps) => {
            open({...props, mode: 'CONFIRM'});
            const myButton = new Subject<ModalButtons>();
            setButton(myButton);
            return new Promise((resolve, reject) => {
                const subscription = myButton && myButton.subscribe(async (value: ModalButtons) => {
                    if (props.syncAction && value === 'CONFIRM') {
                        setIsCallingAction(true);
                        await props.syncAction();
                        setIsCallingAction(false);
                    }
                    close();
                    subscription.unsubscribe();
                    resolve(value);
                });
            });
        }
    };

    return (
        <>
            <ModalContext.Provider value={value}>
                {props.children}
            </ModalContext.Provider>

            <Dialog
                open={modal.isOpen}
                onClose={(e, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        return;
                    }
                    button && button.next('CANCEL');
                }}
                PaperComponent={Paper}
            >
                <DialogTitle>{modal.title}</DialogTitle>
                <LinearProgress hidden={!isCallingAction}/>
                <DialogContent>
                    {typeof modal.message === 'string' ? <p>{modal.message}</p> : modal.message}
                </DialogContent>
                <DialogActions>
                    {modal.mode === 'CONFIRM' &&
                        <>
                            <Button color="inherit" disabled={isCallingAction} onClick={() => {
                                button && button.next('CANCEL');
                            }}>
                                <span>{modal.cancelText || t('modal.cancel')}</span>
                            </Button>
                            <Button color={modal.confirmColor} variant={'contained'} disabled={isCallingAction} onClick={() => {
                                button && button.next('CONFIRM');
                            }}>
                                <span>{modal.confirmText || t('modal.confirm')}</span>
                            </Button>
                        </>
                    }
                    {modal.mode === 'INFO' &&
                        <>
                            <Button variant="contained" color={modal.confirmColor} disabled={isCallingAction} onClick={() => {
                                button && button.next('CONFIRM');
                            }}>
                                <span>{modal.confirmText || t('modal.confirm')}</span>
                            </Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
};

export const useModal = () => {
    return useContext(ModalContext);
}
